import {get,post} from "@/utils/axios"
//生成订单
const generatorNewPurchaseOrder = p => post('api-order/order/generatorNewPurchaseOrder', p);


//获取商品套餐详情
const getBillingModeList = p => get('api-common/goods/getBillingModeList', p);

//获取用户的实例列表
const getInstanceList = p => get('api-order/order/getInstanceList', p);

//获取实例的订单列表
const getInstanceOrderList = p => get('api-order/order/getOrderInstanceList', p);

//实例详情
const getOrderDescription = p => get('api-order/order/getOrderDescription', p)

//获取实例订单详情
const getInstanceOrderInfo = p => get('api-order/order/getInstanceOrderInfo', p);

//生成续费、升级、扩容订单
const generatorOtherOrder = p => post('api-order/order/generatorOtherOrder', p);

//获取订单详情
const getOrderInfo = p => get('api-order/order/getOrderInfo', p);

export default {
    generatorNewPurchaseOrder,
    getBillingModeList,
    getInstanceList,
    getInstanceOrderList,
    getOrderDescription,
    getInstanceOrderInfo,
    generatorOtherOrder,
    getOrderInfo
}