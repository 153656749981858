<template>
  <div class="page flex-col">
    <el-row>
      <!--      <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="0" >-->
      <!--        <my-membership></my-membership>-->
      <!--      </el-col>-->
      <el-col :span="24" style="height: 42px; background: #2D3142;" :sm="0">
        <el-col class="header-title">我的实例</el-col>
      </el-col>
      <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
        <my-membership></my-membership>
      </el-col>
      <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="0">
        <div class="box4 flex-row justify-between">
          <span class="txt4" style="margin-left: -1050px;">我的实例</span>
          <!--          <el-button type="success" plain class="bd8 flex-col justify-center align-center">-->
          <!--            <span class="txt5">查看全部订单</span>-->
          <!--          </el-button>-->
        </div>
      </el-col>
      <el-col :span="24" :sm="0">
        <el-form ref="form">
          <el-form-item>
            <el-col :span="24" class="mobile-item" v-for="(item,index) in  orders" :key="index">
              <div :span="24" @click="goDescripertion(item)">
                <el-col :span="5" class="mobile-product_img">
                  <img :src="item.saasProductLogo" style="width: 100%;height: 100%">
                </el-col>

              <el-col :span="15">
                <el-col :span="18" class="mobile-product_tile">{{ item.saasProductName }}</el-col>
                <el-col :span="24">
                  <el-col style="margin: -7px 0px 0px 12px;width: 13%;">域名</el-col>
                  <el-col class="mobile-product-value">{{ item.instanceDomain }}</el-col>
                </el-col>
                <el-col :span="24">
                  <el-col style="margin: -7px 0px 0px 12px;width: 27%;">到期时间</el-col>
                  <el-col class="mobile-product-value">2023-06-25 22:22:22</el-col>
                </el-col>

                <el-col :span="24">
                  <el-col style="margin: -7px 0px 0px 12px;width: 27%;">服务状态</el-col>
                  <el-col class="mobile-status" v-show="item.orderEndTime == null">未开始</el-col>
                  <el-col class="mobile-product-value-1" v-show="Date.parse(item.orderEndTime) > new Date()">服务中
                  </el-col>
                  <el-col class="mobile-product-value-2" v-show="Date.parse(item.orderEndTime) <= new Date()">已经结束
                  </el-col>
                </el-col>
              </el-col>
              </div>
              <el-col>
                <div class="line-mobile"></div>
              </el-col>
              <el-col :span="24" style="margin: 2px 2px 2px 2px;">
                <el-col :span="6">
                  <div style="margin: 2px"></div>
                </el-col>
                <el-col :span="6">
                  <el-button v-show="item.orderEndTime != null" type="success" plain class="mobile-xufei"
                             @click="goPage('/goodsRenewal', item.instanceId, false)">
                    续费
                  </el-button>
                </el-col>
                <el-col :span="6">
                  <el-button v-show="item.orderEndTime != null" type="success" plain class="mobile-kuorong"
                             @click="goPage('/goodsUpgrade', item.instanceId, true)">
                    扩容
                  </el-button>
                </el-col>
                <el-col :span="6">
                  <el-button v-show="item.orderEndTime != null" type="success" plain class="mobile-shengji"
                             @click="goPage('/goodsUpgrade', item.instanceId, false)">
                    升级
                  </el-button>
                </el-col>
              </el-col>

            </el-col>
          </el-form-item>
          <el-pagination
              layout="prev, pager, next"
              :total="page.total"
              class="table-page"
              @current-change="handleCurrentChange"
              :current-page="page.pageNo"
              :page-size="page.pageSize"
          >
          </el-pagination>
        </el-form>
      </el-col>
      <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="0">
        <el-form ref="form">
          <el-form-item>
            <div class="box5 flex-row justify-between">
              <div class="outer1 flex-col justify-center" v-for="(item,index) in  orders" :key="index">
                <div class="bd9 flex-col" @click="goDescripertion(item)">
                  <div class="wrap1 flex-col">
                    <img :src="item.saasProductLogo">
                  </div>
                  <div class="instance-content">
                    <div class="content-text text-beyond" :title="item.saasProductName"> {{
                        item.saasProductName
                      }}
                    </div>
                    <!--                      <div class="content-text text-beyond" :title="item.instanceId">实例ID：{{ item.instanceId }}</div>-->
                    <div class="content-text text-beyond" :title="item.instanceDomain">域名：{{
                        item.instanceDomain
                      }}
                    </div>
                    <div class="content-text">到期时间：{{ item.orderEndTime }}</div>
                    <div>
                      <span class="txt6">服务状态：</span>
                      <span class="info6red" v-show="item.orderEndTime == null">未开始</span>
                      <span class="info6" v-show="Date.parse(item.orderEndTime) > new Date()">服务中</span>
                      <span class="info6red" v-show="Date.parse(item.orderEndTime) <= new Date()">已结束</span>
                    </div>
                  </div>
                </div>
                <el-button v-show="item.orderEndTime != null" type="success" plain class="info7"
                           @click="goPage('/goodsRenewal', item.instanceId, false)">
                  <span style="margin-left: -6px">续费</span>
                </el-button>
                <el-button v-show="item.orderEndTime != null" type="success" plain class="word12"
                           @click="goPage('/goodsUpgrade', item.instanceId, true)">
                  <span style="margin-left: -6px">扩容</span>
                </el-button>
                <el-button v-show="item.orderEndTime != null" type="success" plain class="word13"
                           @click="goPage('/goodsUpgrade', item.instanceId, false)">
                  <span style="margin-left: -6px">升级</span>
                </el-button>
              </div>
            </div>
          </el-form-item>
          <el-pagination
              layout="prev, pager, next"
              :total="page.total"
              class="table-page"
              @current-change="handleCurrentChange"
              :current-page="page.pageNo"
              :page-size="page.pageSize"
          >
          </el-pagination>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MembershipBar from '../common/MenberShipBar'
import orderApi from "../../api/orderApi";

export default {
  components: {
    'my-membership': MembershipBar
  },
  name: "index",
  data() {
    return {
      userInfo: {
        userName: this.$store.state.userInfo.userInfo.userAuthAccount,
        companyName: this.$store.state.userInfo.userInfo.companyName,
      },
      orders: [],
      page: {
        pageNo: 1,
        pageSize: 4,
        total: 0
      }
    }
  },
  mounted() {
    this.getInstanceList();
  },
  methods: {
    goPage(name, instanceId, ifExpansion) {
      if (name) {
        sessionStorage.setItem("ifExpansion", ifExpansion);
        sessionStorage.setItem("instanceId", instanceId);
        this.$router.push({path: name});
      }
    },
    getInstanceList() {
      let userId = this.$store.state.userInfo.userInfo.saasUserId;
      let obj = this;
      orderApi.getInstanceList({
        "userId": userId,
        "pageNo": obj.page.pageNo,
        "pageSize": obj.page.pageSize
      }).then((res) => {
        console.log(res);
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        if (res.data) {
          this.orders = res.data.records;
          this.page.total = res.data.total;
          console.log(this.page.total);
        }
      })
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getInstanceList();
    },
    goDescripertion(item) {
      this.$router.push({
        path: '/orderList',
        query: {
          instanceId: item.instanceId
        }
      })
    },
    buy(index) {
      console.log(index)
      this.$router.push({path: '/login'})
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .page {
    height: 920px !important;
    background-color: #F5F5F5 !important;
  }
}

.mobile-shengji {
  border-radius: 14px;
  border: 1px solid #3764D7;
  width: 72px;
  height: 28px;
  display: block;
  margin: 12px 0px 0px 0px;
  color: rgba(55, 100, 215, 1);
  font-size: 12px;
  /*line-height: 20px;*/
  text-align: center;
}

.mobile-kuorong {
  border-radius: 14px;
  border: 1px solid #3764D7;
  width: 72px;
  height: 28px;
  margin: 12px 0px 0px 0px;
  color: rgba(55, 100, 215, 1);
  font-size: 12px;
  /*line-height: 20px;*/
  text-align: center;
}

.mobile-xufei {
  border-radius: 14px;
  border: 1px solid #3764D7;
  width: 72px;
  height: 28px;
  display: block;
  margin: 12px 0px 0px 0px;
  color: rgba(55, 100, 215, 1);
  font-size: 12px;
  /*line-height: 20px;*/
  text-align: center;
}

.line-mobile {
  margin: 7px 16px 0px 16px;
  border-top: 1px solid #E6E6E6;
}
.mobile-status {
  height: 21px;
  font-size: 12px;
  color: rgb(197, 12, 12);
  font-weight: 400;
  line-height: 16px;
  width: 100%;
  margin-right: 5px;
  text-align: right;
  float: unset;
}
.mobile-product-value-1 {
  height: 21px;
  color: rgba(36, 162, 18, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: 100%;
  margin-right: 5px;
  text-align: right;
  float: unset;

}

.mobile-product-value-2 {
  height: 21px;
  font-size: 12px;
  color: rgb(197, 12, 12);
  font-weight: 400;
  line-height: 16px;
  width: 100%;
  margin-right: 5px;
  text-align: right;
  float: unset;

}

.mobile-product-value {
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  width: 100%;
  margin-right: 5px;
  text-align: right;
  float: unset;

}

.mobile-product_tile {
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 13px 0px 0px 12px;
}

.mobile-product_img {
  margin: 12px 0px 0px 16px;
  width: 78px;
  height: 78px;
}

.mobile-item {
  width: 96.66667%;
  height: 178px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin: 8px 8px 0px 8px;
}

.header-title {
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 18px;
  margin: 11px 0px 10px 18px;
}

.instance-content {
  margin: 0px 0px 0px 2vw;
}

.content-text {
  white-space: nowrap
}

.text-beyond {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-page {
  text-align: center;
  margin-top: 20px;
}

.page {
  position: relative;
  /*width: 1920px;*/
  height: 1000px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
}

.el-button {
  line-height: 0;
}

.el-button--success.is-plain:focus, .el-button--success.is-plain:hover {
  background: rgb(52, 100, 224);
  border-color: rgb(52, 100, 224);
  color: #FFF;

}

.el-button--success.is-plain:hover .txt5 {
  color: #FFFFFF;
}


.el-button--success.is-plain {
  background: #ffffff;
}


.box4 {
  /*width: 32rem;
  height: 1.2rem;*/
  /*margin: 0.934rem 0 0 9.6rem;*/
}

.txt4 {
  width: 3.414rem;
  height: 1.2rem;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 0.853rem;

  white-space: nowrap;
  line-height: 1.2rem;
  text-align: left;
}

.bd8 {
  height: 0.854rem;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  margin-top: 0.347rem;
  width: 2.987rem;
}

.txt5 {

  box-sizing: border-box;
  width: 2.24rem;
  height: 0.534rem;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 0.373rem;
  white-space: nowrap;
  line-height: 0.534rem;
  text-align: left;
}

.box4 {
  /*box-sizing: border-box;
  width: 1200px;
  height: 45px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 35px 0 0 360px;*/

  box-sizing: border-box;
  /*width: 1200px;*/
  height: 45px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  /*margin-left: 350px;*/
  margin-top: 35px;
}

.txt4 {
  box-sizing: border-box;
  width: 128px;
  height: 45px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 32.00px;

  white-space: nowrap;
  line-height: 45.00px;
  text-align: left;
}

.bd8 {
  box-sizing: border-box;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  width: 112px;
  justify-content: center;
  align-items: center;
}

.txt5 {
  box-sizing: border-box;
  width: 84px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 20.00px;
  text-align: left;
}

.box5 {
  box-sizing: border-box;
  /*width: 1200px;*/
  height: 448px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  /*margin-left: 350px;*/
  margin-top: 10px;
}

.outer1 {
  box-sizing: border-box;
  z-index: 134;
  height: 410px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  width: 256px;
  position: relative;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 1px 7px 0px rgba(156, 156, 156, 0.5);
  margin-right: 50px;
}

.outer1:hover {
  box-sizing: border-box;
  z-index: 134;
  height: 410px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 15px 0px rgb(54 54 54 / 51%);
  border: none;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  width: 256px;
  position: relative;
  justify-content: center;
}

.bd9 {
  box-sizing: border-box;
  width: 241px;
  display: flex;
  flex-direction: column;
}

.wrap1 {
  box-sizing: border-box;
  width: 100%;
  /*height: 131px;*/
  /*background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdc1fd911e181025fcf9a4a8dde863327ca0b56d3e0005e2654891acb947a6dfe) 100% no-repeat;*/
  display: flex;
  flex-direction: column;
}

.word9 {
  box-sizing: border-box;
  width: 170px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 18.00px;

  white-space: nowrap;
  line-height: 25.00px;
  text-align: left;
  margin: 32px 0 0 36px;
}

.word10 {
  box-sizing: border-box;
  width: 128px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 20.00px;
  text-align: left;
  margin: 24px 0 0 57px;

}

.info5 {
  box-sizing: border-box;
  width: 143px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 20.00px;
  text-align: left;
  margin: 16px 0 0 50px;
}

.word11 {
  box-sizing: border-box;
  width: 194px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 20.00px;
  text-align: left;
  margin: 16px 0 0 24px;
}

.wrap2 {
  box-sizing: border-box;
  width: 112px;
  height: 20px;
  overflow-wrap: break-word;
  text-align: left;
  white-space: nowrap;
  font-size: 0.00;
  margin: 16px 0 0 65px;
}

.txt6 {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info6 {
  font-size: 14px;
  color: rgba(36, 162, 18, 1);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info6red {
  font-size: 14px;
  color: rgb(197, 12, 12);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info7 {
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  z-index: 139;
  position: absolute;
  left: 24px;
  top: 365px;
  width: 56px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 14px;
  white-space: nowrap;
  /*line-height: 20px;*/
  text-align: left;
}

.word12 {
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  z-index: 142;
  position: absolute;
  left: 85px;
  top: 365px;
  width: 56px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 14px;
  white-space: nowrap;
  text-align: left;
}

.word13 {
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  z-index: 145;
  position: absolute;
  left: 156px;
  top: 365px;
  width: 56px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 14px;
  white-space: nowrap;
  text-align: left;
}
</style>
